import _ from "lodash";

import {
  Avatar,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import config from "config";
import CustomLink from "./Link";
import favicon from "../assets/favicon.svg";
import User from "data/firestore/user";

// ==============================|| LOGO ||============================== //

const Logo = ({ user, sx }) => {
  const avatar = user ? user.profilePicture : User.genericProfilePicture;

  return (
    <List
      sx={{ ...sx, width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      style={{ padding: 0, width: "fit-content" }}
    >
      <CustomLink
        dest={_.replace(config.defaultPath, "/", "")}
        style={{ textDecoration: "unset" }}
      >
        <ListItem style={{ padding: 10, borderRadius: 24 }} button>
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            color="error"
            size="small"
            sx={{
              ".MuiBadge-badge": { top: 35 },
              display: user ? "none" : "initial",
            }}
          >
            <IconButton
              style={{
                border: 0,
                borderRadius: 24,
                background:
                  "linear-gradient(to bottom left, rgba(255, 239, 98, 1) 25%, rgba(255, 172, 51, 1) 100%)",
                color: "white",
                margin: 5,
              }}
            >
              <img src={favicon} alt="Anteater Logo" height={35} width={35} />
            </IconButton>
          </Badge>
          <Avatar
            src={avatar}
            style={{
              marginRight: 5,
              display: user ? "block" : "none",
              height: 48,
              width: 48,
            }}
          />
          <ListItemText
            primary="Keep on Tugging!"
            sx={sx || { display: { xs: "none", sm: "block" } }}
          />
        </ListItem>
      </CustomLink>
    </List>
  );
};

export default Logo;
