import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "routes/Loadable";
import WIPPage from "pages/wip";

// Page routing
const ProgressPage = Loadable(lazy(() => import("pages/progress")));
const TugPage = Loadable(lazy(() => import("pages/tug")));
const HistoryPage = Loadable(lazy(() => import("pages/history")));
const AlbumsPage = Loadable(lazy(() => import("pages/albums")));
const PrivacyPage = Loadable(lazy(() => import("pages/privacy")));
const DownloadPage = Loadable(lazy(() => import("pages/download")));
const VisionPage = Loadable(lazy(() => import("pages/vision")));
const ProfilePage = Loadable(lazy(() => import("pages/profile")));
const MembershipPage = Loadable(lazy(() => import("pages/membership")));
const SearchPage = Loadable(lazy(() => import("pages/search")));
const BackupPage = Loadable(lazy(() => import("pages/backup")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <ProgressPage />,
    },
    {
      path: "tug",
      element: <TugPage />,
    },
    {
      path: "progress",
      element: <ProgressPage />,
    },
    {
      path: "history",
      element: <HistoryPage />,
    },
    {
      path: "albums",
      element: <AlbumsPage />,
    },
    {
      path: "export",
      element: <WIPPage />,
    },
    {
      path: "philosophy",
      element: <VisionPage />,
    },
    {
      path: "privacy",
      element: <PrivacyPage />,
    },
    {
      path: "download",
      element: <DownloadPage />,
    },
    {
      path: "vision",
      element: <VisionPage expandAll={false} />,
    },
    {
      path: "profile",
      element: <ProfilePage />,
    },
    {
      path: "membership",
      element: <MembershipPage />,
    },
    {
      path: "search",
      element: <SearchPage />,
    },
    {
      path: "backup",
      element: <BackupPage />,
    },
  ],
};

export default MainRoutes;
