import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";
import { IconMenu2 } from "@tabler/icons-react";

// project imports
import Logo from "components/Logo";
import HeaderDropdownUser from "components/HeaderDropdownUser";
import HeaderDropdownDemo from "components/HeaderDropdownDemo";
import CurrentSession from "data/user_session";
import useCustomNavigation from "utils/custom_navigation";
import FirebaseAnalytics from "data/firestore/analytics";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useCustomNavigation();

  const [signedIn, setSignedIn] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    CurrentSession.load().then((user) => {
      FirebaseAnalytics.pageView();
      setSignedIn(CurrentSession.isSignedIn);
      setUser(CurrentSession.authenticatedUser);
      if (
        CurrentSession.authenticationStrictlyMatches(user.uid) &&
        user.incompleteSignup
      ) {
        navigate(`profile`);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function menuIcon(onLeftSide) {
    return (
      <ButtonBase sx={{ borderRadius: "12px" }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: "all .2s ease-in-out",
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            "&:hover": {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
            display: onLeftSide
              ? { xs: "none", md: "flex" }
              : { xs: "flex", md: "none" },
            marginLeft: { xs: 1, md: 0 },
            marginRight: { xs: 0, md: 1 },
          }}
          onClick={handleLeftDrawerToggle}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    );
  }

  return (
    <>
      {/* logo & toggler button */}
      {menuIcon(true)}

      <Logo user={user} />
      <Box sx={{ flexGrow: 1 }} />

      <div style={{ marginRight: 5 }}>
        {signedIn ? (
          <HeaderDropdownUser
            onSignOut={() => {
              CurrentSession.SignOut().then(() => {
                setSignedIn(false);
                FirebaseAnalytics.event("sign_out");
                navigate("");
                window.location.reload();
              });
            }}
          />
        ) : (
          <HeaderDropdownDemo
            onSignIn={() => {
              CurrentSession.SignIn().then((isNewUser) => {
                CurrentSession.load().then(() => {
                  setSignedIn(true);
                  FirebaseAnalytics.event(isNewUser ? "sign_up" : "login");
                  window.location.reload();
                });
              });
            }}
          />
        )}
      </div>
      {menuIcon(false)}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
