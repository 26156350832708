import { initializeApp } from "firebase/app";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
// ... other imports like getAuth(), getStorage(), etc.

// 1) Initialize the app
// From Project Settings > General
const firebaseConfig = {
  apiKey: "AIzaSyD0awJF1u_zCwpE4NC6AO1Yn9f5K0neAcg",
  authDomain: "keep-on-tugging.firebaseapp.com",
  databaseURL: "https://keep-on-tugging.firebaseio.com",
  projectId: "keep-on-tugging",
  storageBucket: "keep-on-tugging.appspot.com",
  messagingSenderId: "316297455897",
  appId: "1:316297455897:web:73581993b50f983876fd88",
  measurementId: "G-RQM4184F2H",
};
const app = initializeApp(firebaseConfig);

// 2) Get the *full* Firestore (NOT the Lite version)
const db = getFirestore(app);

// 3) Listen to the user's document
export function watchUserDocument(uid, callback) {
  const userDocRef = doc(db, "users", uid);

  // `onSnapshot` sets up a real-time listener
  return onSnapshot(
    userDocRef,
    (docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data();
        callback(userData);
        // do something with userData
      } else {
        console.log("No user document found!");
      }
    },
    (error) => {
      console.error("Listen failed:", error);
    }
  );
}

// Example usage
// This returns an unsubscribe function you can call to stop listening:
// const unsubscribe = watchUserDocument("someUserId");
// Later, if you need to stop watching:
// unsubscribe();
