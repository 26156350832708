import _ from "lodash";
import moment from "moment";
import Logger from "./logger";

export default class TimeUtils {
  static FullDateString = "YYYY-MM-DD";
  static FullDateTimeString = "YYYY-MM-DD HH:mm:ss";
  static RefreshRate = moment.duration(5, "seconds").asMilliseconds();

  static breakdown(ms, limitToHours = false) {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const years = Math.floor(days / 365);

    if (limitToHours) {
      return {
        hours: hours,
        minutes: minutes % 60,
        seconds: seconds % 60,
      };
    }

    return {
      years: years,
      days: days % 365,
      hours: hours % 24,
      minutes: minutes % 60,
      seconds: seconds % 60,
    };
  }

  static elapsedToString(breakdown, limit = 2, includeSeconds = false) {
    const pieces = [];
    if (breakdown.years > 0) {
      pieces.push(`${breakdown.years.toLocaleString()}y`);
    }
    if (breakdown.days > 0) {
      pieces.push(`${breakdown.days.toLocaleString()}d`);
    }
    if (breakdown.hours > 0) {
      pieces.push(`${breakdown.hours.toLocaleString()}h`);
    }
    if (breakdown.minutes > 0) {
      pieces.push(`${breakdown.minutes.toLocaleString()}m`);
    }
    if (breakdown.seconds > 0 && includeSeconds) {
      pieces.push(`${breakdown.seconds.toLocaleString()}s`);
    }
    return _.join(_.take(pieces, limit), " ") || "0";
  }

  static formatTimer(ms) {
    const breakdown = TimeUtils.breakdown(ms);
    if (breakdown.hours === 0) {
      return `${String(breakdown.minutes).padStart(2, "0")}:${String(
        breakdown.seconds
      ).padStart(2, "0")}`;
    }
    return `${String(breakdown.hours).padStart(2, "0")}:${String(
      breakdown.minutes
    ).padStart(2, "0")}:${String(breakdown.seconds).padStart(2, "0")}`;
  }

  static getAllMonthsSince(year) {
    const currentDate = moment(); // Get the current date
    const currentYear = currentDate.year(); // Get the current year
    const currentMonth = currentDate.month(); // Get the current month (0-based)

    const months = [];

    // Loop through the years from the given year to the current year
    _.range(parseInt(year), currentYear + 1).forEach((y) => {
      // Determine the start and end months for the current year
      const startMonth = 0;
      const endMonth = y === currentYear ? currentMonth : 11;

      // Loop through the months within the current year
      _.range(startMonth, endMonth + 1).forEach((m) => {
        const date = moment({ year: y, month: m, day: 1 }); // Create a moment date for the first day of the month
        months.push(date.format("YYYY-MM-DD"));
      });
    });

    return months;
  }

  static parseDateStrictly(inputDate, inputTime = null) {
    const dateFormats = [
      ["YYYY", "MM", "DD"],
      ["YYYY", "M", "D"],
      ["MM", "DD", "YYYY"],
      ["M", "D", "YYYY"],
    ];
    const separators = ["-", "/"];
    const timeFormats = [
      "",
      "h:mm",
      "H:mm",
      "hh:mm",
      "HH:mm",
      "hh:mm",
      "HH:mm",
    ];
    const secondsFormat = ["", "s", "ss"];
    const timeSuffixes = ["", "A", "a", " A", " a"];

    const dateString = _.trim(`${inputDate} ${inputTime || ""}`);
    for (const date of dateFormats) {
      for (const separator of separators) {
        for (const time of timeFormats) {
          for (const seconds of secondsFormat) {
            for (const suffix of timeSuffixes) {
              const acceptableFormat = _.trim(
                `${date.join(separator)} ${time}${seconds}${suffix}`
              );
              const parsedDate = moment(dateString, acceptableFormat, true);
              if (parsedDate.isValid()) {
                return parsedDate;
              }
            }
          }
        }
      }
    }

    Logger.warn(`[ERROR] Could not parse: ${dateString}`);
    return moment.invalid();
  }

  static parseImportedDuration(input) {
    const minutes = moment.duration(
      input,
      _.includes(input, ":") ? null : "minutes"
    );
    return minutes.as("milliseconds");
  }

  static totalMilliseconds(time) {
    const { days, hours, minutes, seconds } = time;
    const totalMilliseconds =
      days * 24 * 60 * 60 * 1000 +
      hours * 60 * 60 * 1000 +
      minutes * 60 * 1000 +
      seconds * 1000;

    return totalMilliseconds;
  }

  static untilMidnight() {
    const now = moment();
    const midnight = moment().endOf("day");
    return midnight.diff(now, "milliseconds");
  }
}
