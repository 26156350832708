import _ from "lodash";
import moment from "moment";

import FirestoreDocument from "./document";
import Snapshot from "../snapshot";
import TimeUtils from "utils/time";

export default class Snapshots extends FirestoreDocument {
  static COLLECTION = "snapshots";

  constructor(id, data = {}) {
    super();
    this.id = id;
    this.resetWith(data);
  }

  async fetch() {
    try {
      const { id, data } = await super.fetch();
      this.id = id;
      this.setRaw(data);
    } catch (error) {
      // No snapshot exists for user, so reset data
      this.resetWith({});
    }
  }

  get gaps() {
    const imageDates = _.map(this.images, (img) =>
      moment(img.date).startOf("month").format(TimeUtils.FullDateString)
    );
    const timelineDates = _.keys(this.raw);
    return _.map(_.difference(imageDates, timelineDates), (d) =>
      moment(d).format("MMM YYYY")
    );
  }

  get json() {
    const data = {};
    _.forEach(this.raw, (v, k) => (data[k] = v.json));
    data["total"] = this.total.json;
    return data;
  }

  methodsBreakdown(user) {
    const total = _.sum(_.values(this.total.methods));

    // Aggregate data
    const categories = {};
    const methods = {};
    _.forEach(this.total.methods, (duration, methodKey) => {
      const method = user.inferCustomMethod(methodKey);

      if (!_.has(categories, method.category)) {
        categories[method.category] = 0;
      }
      if (!_.has(methods, method.name)) {
        methods[method.name] = {
          name: method.name,
          category: method.category,
          value: 0,
          total: 0,
        };
      }
      categories[method.category] += duration;
      methods[method.name].value += duration;
      methods[method.name].total = total;
    });

    // Transform data
    const reformattedCategories = _.map(categories, (value, key) => ({
      name: key,
      value: value,
      total: total,
    }));

    return {
      categories: reformattedCategories,
      methods: _.values(methods),
    };
  }

  methodsOverTime(user) {
    const allPotentialDates = _.concat(_.keys(this.raw), this.gaps);
    const firstYear = moment(_.first(_.sortBy(allPotentialDates))).year();
    const listOfMonths = TimeUtils.getAllMonthsSince(firstYear);

    // Reformat data
    return _.map(listOfMonths, (month) => {
      const methods = _.get(this.raw, [month, "methods"], {});
      const categories = {};

      _.forEach(methods, (duration, method) => {
        const category = user.inferCustomMethod(method).category;
        if (!_.has(categories, category)) {
          categories[category] = 0;
        }
        categories[category] += duration;
      });

      return {
        x: moment(month).format("MMM YYYY"),
        ...categories,
      };
    });
  }

  monthHasData(formattedDate) {
    return (
      _.has(this.raw, formattedDate) && this.raw[formattedDate].sessions > 0
    );
  }

  monthData(date, subtract = 0) {
    const previousMonth = moment(date)
      .subtract(subtract, "month")
      .startOf("month")
      .format(TimeUtils.FullDateString);
    if (!this.monthHasData(previousMonth)) {
      return new Snapshot();
    }
    return this.raw[previousMonth];
  }

  resetWith(data = {}) {
    this.raw = {};
    this.images = [];
    this.setRaw(data);
    this.saveCheckpoint();
  }

  setRaw(data) {
    this.raw = {};
    _.forEach(data, (v, k) => {
      this.raw[k] = new Snapshot(v);
    });
  }

  timeline(aggregated) {
    const allPotentialDates = _.concat(_.keys(this.raw), this.gaps);
    const firstYear = moment(_.first(_.sortBy(allPotentialDates))).year();
    const listOfMonths = TimeUtils.getAllMonthsSince(firstYear);
    const months = {};

    // Reformat data
    _.forEach(listOfMonths, (month) => {
      const data = _.get(this.raw, month, {
        days: 0,
        duration: 0,
        sessions: 0,
      });

      months[month] = {
        x: month,
        days: data.days,
        duration: data.duration,
        sessions: data.sessions,
      };
    });

    // Calculate running sum
    const orderedList = _.map(_.sortBy(months, "x"), (m) => {
      m.x = moment(m.x).format("MMM YYYY");
      m.hours = _.round(moment.duration(m.duration).asHours(), 1);
      return m;
    });
    // The last data point should be explicitly known for our "running man" icon on the graph
    const lastDataPoint = _.last(_.filter(orderedList, (s) => s.days > 0));
    if (lastDataPoint) {
      lastDataPoint.isLastDataPoint = true;
    }
    if (!aggregated) {
      return orderedList;
    }

    let runningSum = {
      days: 0,
      duration: 0,
      sessions: 0,
    };
    _.forEach(orderedList, (month) => {
      // For months with no tugging, show a gap on the chart
      if (month.days === 0 && !_.includes(this.gaps, month.x)) {
        delete month.days;
        delete month.duration;
        delete month.hours;
        delete month.sessions;
        return;
      }
      month.aggregate = {};

      runningSum.days += month.days;
      month.aggregate.days = runningSum.days;

      runningSum.duration += month.duration;
      month.aggregate.hours = _.round(
        moment.duration(runningSum.duration).asHours(),
        1
      );

      runningSum.sessions += month.sessions;
      month.aggregate.sessions = runningSum.sessions;
    });
    runningSum.hours = _.round(
      moment.duration(runningSum.duration).asHours(),
      1
    );
    return orderedList;
  }

  get total() {
    const total = new Snapshot();
    _.forEach(this.raw, (snapshot, month) => {
      if (month === "total") {
        return;
      }
      total.days += snapshot.days;
      total.duration += snapshot.duration;
      total.sessions += snapshot.sessions;

      _.mergeWith(
        total.methods,
        snapshot.methods,
        (a, b) => (a || 0) + (b || 0)
      );
    });
    return total;
  }

  track(session) {
    const month = moment(session.date)
      .startOf("month")
      .format(TimeUtils.FullDateString);

    if (!_.has(this.raw, month)) {
      this.raw[month] = new Snapshot();
    }
    this.raw[month].track(session);
  }

  get uid() {
    return this.id;
  }

  yearHasData(year) {
    return _.includes(this.years, year);
  }

  get years() {
    return _.uniq(
      _.filter(
        _.map(this.raw, (snapshot, date) => {
          if (snapshot.sessions <= 0 || date === "total") {
            return null;
          }
          return _.parseInt(_.slice(date, 0, 4).join(""));
        })
      )
    );
  }
}
