import { useState, useEffect } from "react";

import ConfettiExplosion from "react-confetti-explosion";
import { Badge, Button, Grid, Tooltip, Typography } from "@mui/material";
import {
  IconAlertTriangle,
  IconFlame,
  IconFlameFilled,
  IconHourglassHigh,
} from "@tabler/icons-react";

// project imports
import CurrentSession from "data/user_session";
import { watchUserDocument } from "data/firestore/config-full";
import LastTugged from "./LastTugged";
import CustomChip from "./Chip";
import TimeUtils from "utils/time";
import useCustomNavigation from "utils/custom_navigation";

const HeaderDropdownUser = () => {
  const navigate = useCustomNavigation();

  const [lastTugged, setLastTugged] = useState(null);
  const [streak, setStreak] = useState(0);
  const [streakInDanger, setStreakInDanger] = useState(false);
  const [streakWasInDanger, setStreakWasInDanger] = useState(false);
  const [untilMidnight, setUntilMidnight] = useState(null);

  function updateStreak() {
    setStreak(CurrentSession.user.streak);
    setStreakInDanger(CurrentSession.user.streakInDanger);
    if (CurrentSession.user.streakInDanger) {
      setStreakWasInDanger(true);
    }
    setLastTugged(CurrentSession.user.lastTugged);
  }

  useEffect(() => {
    CurrentSession.load().then((user) => {
      // Watch for changes from database
      watchUserDocument(user.id, (userData) => {
        CurrentSession.user.load(false, userData);
        updateStreak();
      });
      setStreakWasInDanger(CurrentSession.user.streakInDanger);
    });
  }, []);

  // Automatically update the countdown until midnight
  useEffect(() => {
    const interval = setInterval(() => {
      setUntilMidnight(TimeUtils.untilMidnight());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Automatically reset streak at midnight
  useEffect(() => {
    function scheduleMidnightUpdate() {
      setUntilMidnight(TimeUtils.untilMidnight());

      setTimeout(() => {
        updateStreak();
        setStreakWasInDanger(CurrentSession.user.streakInDanger);
        scheduleMidnightUpdate(); // Re-schedule for the next midnight
      }, TimeUtils.untilMidnight());
    }

    scheduleMidnightUpdate(); // Start the first scheduled update

    return () => clearTimeout(scheduleMidnightUpdate);
  }, []);

  const RenderedIcon =
    streak > 0 && !streakInDanger ? IconFlameFilled : IconFlame;
  return (
    <Grid container alignContent="center" alignItems="center" wrap="nowrap">
      <Grid item>
        {streakInDanger ? (
          streak <= 0 ? (
            <Tooltip title="Streak" arrow>
              <CustomChip
                color="info"
                label="You got this :)"
                style={{ marginRight: 5, fontSize: "1.5em" }}
                sx={{ "& span": { overflow: "visible" } }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Countdown until end of streak :(" arrow>
              <CustomChip
                icon={<IconHourglassHigh />}
                color={untilMidnight < 21600000 ? "error" : "warning"}
                label={TimeUtils.formatTimer(untilMidnight)}
                style={{ marginRight: 5, fontSize: "1.5em" }}
              />
            </Tooltip>
          )
        ) : (
          <LastTugged date={lastTugged} style={{ fontSize: "1.25em" }} />
        )}
      </Grid>
      <Button
        variant="text"
        onClick={() => {
          navigate("tug");
        }}
      >
        <Grid item style={{ marginLeft: 15 }}>
          <Typography
            variant="h2"
            style={{
              color:
                streak > 0
                  ? streakInDanger
                    ? "#ffab00"
                    : "#00c853"
                  : "#78909c",
            }}
          >
            {`${Math.max(streak, 0).toLocaleString()}d`}
          </Typography>
          {streak > 0 && streakWasInDanger && !streakInDanger && (
            <ConfettiExplosion />
          )}
        </Grid>
        <Grid item>
          <Badge
            badgeContent={
              streakInDanger && streak > 0 ? (
                <IconAlertTriangle
                  size={15}
                  style={{ color: "orange", marginRight: 5 }}
                />
              ) : (
                0
              )
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <RenderedIcon
              stroke={1.5}
              size="1.5rem"
              color={streak > 0 ? "orangered" : "gray"}
            />
          </Badge>
        </Grid>
      </Button>
    </Grid>
  );
};

export default HeaderDropdownUser;
