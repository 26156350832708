import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import {
  IconMoodHappy,
  IconMoodPlus,
  IconMoodPuzzled,
  IconMoodSmile,
  IconMoodSmileBeam,
} from "@tabler/icons-react";
import TimeUtils from "utils/time";
import CustomChip from "./Chip";
import CurrentSession from "data/user_session";

export default function LastTugged({
  date,
  style = null,
  isCurrentUser = false,
}) {
  const [color, setColor] = useState("info");
  const [displayString, setDisplayString] = useState("New User!");
  const [Icon, setIcon] = useState(IconMoodPlus);

  function calculate() {
    let dateToCompare = date;
    // If this is the current user, pull directly from their current session rather than a cached entry
    if (isCurrentUser) {
      dateToCompare = CurrentSession.user.lastTugged;
    }
    const elapsed = moment().diff(dateToCompare);
    if (_.isNaN(elapsed)) {
      setColor("info");
      setDisplayString("New User!");
    } else if (elapsed > moment.duration(3, "days").asMilliseconds()) {
      setColor("error");
      setIcon(IconMoodPuzzled);
    } else if (elapsed > moment.duration(1, "days").asMilliseconds()) {
      setColor("warning");
      setIcon(IconMoodSmile);
    } else if (elapsed > moment.duration(6, "hours").asMilliseconds()) {
      setColor("success");
      setIcon(IconMoodSmileBeam);
    } else if (elapsed && date) {
      setColor("success");
      setIcon(IconMoodHappy);
    }
    const breakdown = TimeUtils.breakdown(elapsed);
    const display = TimeUtils.elapsedToString(breakdown, 2);
    if (!breakdown) {
      setDisplayString(null);
    } else if (display === "0") {
      setDisplayString("Just tugged!");
    } else {
      setDisplayString(display);
    }
  }

  useEffect(() => {
    if (date) {
      calculate();
      const intervalId = setInterval(calculate, TimeUtils.RefreshRate);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      setColor("info");
      setDisplayString("New user!");
      setIcon(IconMoodPlus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const renderedIcon = <Icon />;
  return (
    <Tooltip title="Since Last Tug" arrow>
      <CustomChip
        icon={renderedIcon}
        color={color}
        label={displayString}
        style={{ marginRight: 5, ...style }}
      />
    </Tooltip>
  );
}
